<template>
  <b-navbar toggleable="xl" type="info" sticky>
    <div class="brand">
      <b-navbar-brand class="logo">
        <a href="">
          <img src="@/assets/FINCH_MOBILE.svg" alt="Finch_logo" />
        </a>
      </b-navbar-brand>
    </div>
    <b-navbar-toggle target="nav-collapse">
      <template #default="{ expanded }">
        <b-icon v-if="expanded" icon="x"></b-icon>
        <b-icon v-else icon="list"></b-icon>
      </template>
    </b-navbar-toggle>
    <b-collapse id="nav-collapse" v-model="visibleCollapseMenu" is-nav>
      <b-navbar-nav>
        <b-nav-item href="#projects" class="nav-projects"> Проекты </b-nav-item>
        <b-nav-item href="#benefits" class="nav-benefits">
          Преимущества
        </b-nav-item>
        <b-nav-item href="#types" class="nav-types">
          Типы приложений
        </b-nav-item>
        <b-nav-item href="#stages" class="nav-stages">Этапы работ</b-nav-item>
        <b-nav-item href="#competencies" class="nav-competencies">
          Компетенции
        </b-nav-item>
        <b-nav-item href="#contacts" class="nav-contacts">
          Контакты
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: "NavigationComponent",
  data: function () {
    return {
      visibleCollapseMenu: false,
    };
  },
  methods: {
    hideCollapseMenu() {
      this.visibleCollapseMenu = false;
    },
  },
};
</script>

<style lang="scss">
.navbar {
  height: 125px;
  align-items: center;
  justify-content: space-between;
  background: white;
  color: black;
  padding: 45px 180px;
  &-nav {
    align-items: center;
    .nav-item {
      padding: 0 1.25vw;
    }
  }
  li a {
    color: #000000;
    padding: 0 !important;
  }
  .navbar-toggler {
    border: none;
    box-shadow: none;
    &:focus {
      box-shadow: none;
    }
  }
  .brand {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .logo {
    width: 200px;
    img {
      width: 100%;
    }
  }
}
.navbar-collapse.collapse.show {
  width: 100vw;
  margin: 12px -16px 0;
  padding: 8px 16px;
  height: auto;
  background: #fafafa;
  .li a {
    padding: 16px 0px;
  }
}
.navbar-collapse {
  justify-content: flex-end;
}
@media (max-width: 1410px) {
  .navbar {
    padding: 25px 90px;
    .logo {
      width: 160px;
    }
  }
}
@media (max-width: 1200px) {
  .navbar {
    padding: 12px 90px;
    height: 60px;
  }
  .navbar-nav {
    align-items: normal;
  }
  .navbar li a {
    justify-content: normal;
  }
  .navbar-collapse.collapse.show {
    width: 100vw;
    margin: 8px -90px 0;
    padding: 8px 90px;
  }
}
@media (max-width: 768px) {
  .navbar {
    padding: 8px 16px;
    height: 60px;
  }
  .navbar-collapse.collapse.show {
    width: 100vw;
    margin: 8px -16px 0;
    padding: 8px 16px;
  }
}
</style>
