<template>
  <b-card class="card">
    <b-card-text class="card__body">
      <h1 class="card__title underlined">{{ title }}</h1>
      <p class="card__description">{{ description }}</p>
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  name: "CardComponent",
  props: {
    title: String,
    description: String,
    icon: String,
  },
};
</script>

<style scoped lang="scss">
.card {
  height: 100%;

  &-body {
    padding: 0 40px;
  }

  border: none;

  &.notLastElement {
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0;
  }

  &__title {
    width: fit-content;
    background-position-y: calc(100% - 9px);
  }
}

.slick-slide {
  &:not(:first-of-type),
  &:not(:last-of-type) {
    .card {
      width: calc(100% - 24px) !important;
      margin: 0 12px;
    }
  }

  &:first-of-type {
    .card {
      width: calc(100% - 12px) !important;
      margin-left: 0px;
      margin-right: 12px;
    }
  }

  &:last-of-type {
    .card {
      width: calc(100% - 12px) !important;
      margin-left: 12px;
      margin-right: 0px;
    }
  }
}

@media (max-width: 1200px) {
  .card {
    &-body {
      padding: 16px;
    }
    &__title {
      background-position-y: calc(100% - 3px);
    }
  }
}
</style>
