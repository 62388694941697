<template>
  <div id="app">
    <NavigationComponent ref="childComponent" />
    <div class="main_body" @click="hideCollapseMenu">
      <div class="preview" id="preview">
        <div class="preview__info">
          <h1 class="preview__header">
            Разработка сложных мобильных приложений и сервисов на заказ
          </h1>
          <h3 class="preview__description opacity-50">
            Полный цикл разработки iOS и Android приложений для вашего бизнеса
          </h3>
          <button
            type="button"
            class="btn button-text"
            data-toggle="modal"
            data-target=".bd-example-modal-lg"
            @click="showModal = true"
          >
            Связаться с нами
          </button>
        </div>
        <div class="preview__image">
          <img src="@/assets/phones.png" alt="phones" />
        </div>
      </div>

      <div class="cards" id="trust_us">
        <div>
          <b-row>
            <b-col
              xl="4"
              lg="4"
              md="4"
              sm="12"
              cols="12"
              v-for="(card, id) in cards"
              :key="id"
            >
              <Card
                :title="card.title"
                :description="card.description"
                :class="{ notLastElement: id !== cards.length - 1 }"
              />
            </b-col>
          </b-row>
        </div>
      </div>

      <div class="projects" id="projects">
        <div class="projectsHeader">
          <h2>
            <span class="underlined">Наши проекты</span>
          </h2>
          <div class="carouselArrows">
            <span class="arrow" @click="prev('projectsCarousel')">
              <ArrowLeft />
            </span>
            <span class="arrow" @click="next('projectsCarousel')">
              <ArrowRight />
            </span>
          </div>
        </div>
        <b-carousel
          id="projectsCarousel"
          ref="projectsCarousel"
          indicators
          :controls="!isDesktop"
          no-animation
        >
          <b-carousel-slide
            v-for="(project, idx) in projects"
            :key="idx"
            img-blank
            :img-alt="project.title"
          >
            <ProjectComponent
              :title="project.title"
              :description="project.description"
              :item-list="project.itemList"
              :desktop-images="project.desktopImages"
              :mobile-images="project.mobileImages"
              :class="{ simpleDesign: idx % 2 !== 0 }"
              @showModal="showModal = true"
            />
          </b-carousel-slide>
        </b-carousel>
      </div>

      <div id="benefits" class="benefits">
        <h2 class="benefits__title">
          <span class="underlined">
            Разработка мобильных приложений для вашего бизнеса
          </span>
        </h2>
        <div class="benefits__container">
          <b-row>
            <b-col
              :class="benefit.classes"
              xl="4"
              lg="4"
              md="12"
              sm="12"
              cols="12"
              v-for="(benefit, idx) in benefits"
              :key="idx"
            >
              <BenefitComponent
                :icon="benefit.icon"
                :type="benefit.type"
                :title="benefit.title"
                :description="benefit.description"
                :id="String(idx) + '_benefit_description'"
              />
            </b-col>
          </b-row>
        </div>
      </div>

      <div class="types" id="types">
        <h2>
          <span class="underlined">Типы приложений</span>
        </h2>
        <p class="types__description opacity-50">
          Ниже приведен список наиболее часто встречающихся запросов на
          разработку
        </p>
        <div class="types__container">
          <b-row>
            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
              cols="12"
              v-for="(type, idx) in applicationTypes"
              :key="idx"
            >
              <div class="type__info">
                <h3 class="type__title">{{ type.title }}</h3>
                <p class="type__description opacity-50">
                  {{ type.description }}
                </p>
              </div>
            </b-col>
          </b-row>
        </div>

        <button
          type="button"
          class="btn button-text"
          data-toggle="modal"
          data-target=".bd-example-modal-lg"
          @click="showModal = true"
        >
          Обсудить проект
        </button>
      </div>

      <div class="results" id="stages">
        <div class="projectsHeader">
          <h2>
            <span class="underlined">Этапы работ</span>
          </h2>
          <div class="carouselArrows">
            <span class="arrow" @click="prev('stagesCarousel')">
              <ArrowLeft />
            </span>
            <span class="arrow" @click="next('stagesCarousel')">
              <ArrowRight />
            </span>
          </div>
        </div>
        <b-carousel
          id="stagesCarousel"
          ref="stagesCarousel"
          indicators
          no-animation
        >
          <template v-for="(resultGroup, idx) in resultsDesktop">
            <b-carousel-slide
              v-if="isDesktop"
              img-blank
              :key="idx"
              img-alt="none"
            >
              <b-row>
                <b-col
                  v-for="(result, id) in resultGroup"
                  :key="idx + '_' + id"
                  class="results__item"
                >
                  <h3 class="results__item-number">{{ result.number }}</h3>
                  <h3 class="results__item-title">{{ result.title }}</h3>
                </b-col>
              </b-row>
            </b-carousel-slide>
          </template>
          <template v-for="(resultGroup, idx) in resultsMobile">
            <b-carousel-slide
              v-if="!isDesktop"
              img-blank
              :key="idx"
              img-alt="none"
            >
              <b-row>
                <b-col
                  v-for="(result, id) in resultGroup"
                  :key="idx + '_' + id"
                  class="results__item"
                >
                  <h3 class="results__item-number">{{ result.number }}</h3>
                  <h3 class="results__item-title">{{ result.title }}</h3>
                </b-col>
              </b-row>
            </b-carousel-slide>
          </template>
        </b-carousel>
        <button
          type="button"
          class="btn button-text"
          data-toggle="modal"
          data-target=".bd-example-modal-lg"
          @click="showModal = true"
        >
          Рассчитать стоимость
        </button>
      </div>

      <!-- <div class="results" id="stages">
        <h2>
          <span class="underlined">Этапы работ</span>
        </h2>
        <b-container class="results__container">
          <b-row>
            <b-col
              xl="6"
              lg="6"
              md="12"
              sm="12"
              cols="12"
              v-for="(resultGroup, idx) in results"
              :key="idx"
            >
              <b-list-group flush>
                <b-list-group-item
                  v-for="(result, idx) in resultGroup"
                  :key="idx"
                  style="display: flex; gap: 1.25vw"
                >
                  <h3 class="opacity-50">{{ result.number }}</h3>
                  <h3>{{ result.title }}</h3>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </b-container>

        <button
          type="button"
          class="btn button-text"
          data-toggle="modal"
          data-target=".bd-example-modal-lg"
          @click="showModal = true"
        >
          Рассчитать стоимость
        </button>
      </div> -->

      <div class="competencies" id="competencies">
        <h2 class="competencies__title">
          <span class="underlined">
            Сфера компетенций компании FINCH MOBILE
          </span>
        </h2>

        <div class="competencies__items">
          <span
            class="badge competencies__item"
            v-for="(competence, idx) in competencies"
            :key="idx"
          >
            <p>{{ competence }}</p>
          </span>
        </div>
      </div>

      <div class="questions" id="questions">
        <h2 class="questions__title">
          <span class="underlined">Остались вопросы?</span>
        </h2>
        <p class="questions__description opacity-50">
          Свяжитесь с нами и мы подробно обсудим ваш проект и ответим на все
          интересующие вопросы
        </p>

        <button
          type="button"
          class="btn button-text"
          data-toggle="modal"
          data-target=".bd-example-modal-lg"
          @click="showModal = true"
        >
          Оставьте заявку
        </button>
      </div>

      <div class="contacts" id="contacts">
        <h1>
          <a href="mailto:info@finch-mobile.com"> info@finch-mobile.com </a>
        </h1>
        <div class="contacts__item social-media">
          <a href="https://t.me/dshipachev" class="benefit__icon">
            <img src="@/assets/icons/telegram.svg" />
          </a>
          <a href="http://wa.me/79265222770" class="benefit__icon">
            <img src="@/assets/icons/whatsapp.png" />
          </a>
        </div>
        <p class="contacts__phone">
          <a href="tel:+7(926)5222770"> +7 (926) 522 27 70 </a>
        </p>
        <b-container fluid class="contacts__container">
          <b-row>
            <b-col
              xl="6"
              lg="6"
              md="12"
              sm="12"
              cols="12"
              class="contacts__item"
            >
              <p class="contacts__address">
                Москва, 115304, вн.тер.г. Муниципальный округ Царицыно, ул.
                Каспийская дом 22, корпус 1,строение 5, помещ./ком./оф.
                IX/17А/179
              </p>
            </b-col>
            <b-col
              xl="6"
              lg="6"
              md="12"
              sm="12"
              cols="12"
              class="contacts__item"
            >
              <p>© 2022 Finch Mobile</p>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>

    <div
      v-if="showModal"
      class="modal bd-example-modal-lg"
      id="bd-example-modal-lg"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="false"
      data-backdrop="false"
    >
      <div class="modal-dialog modal-lg modal-fullscreen">
        <div class="modal-content">
          <div class="modal-body">
            <button
              type="button"
              class="close close-modal-btn"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&#x2715;</span>
            </button>
            <h2 class="questions__title">
              <span class="underlined">
                Рассчитаем стоимость вашего проекта
              </span>
            </h2>
            <p class="opacity-50">
              И предоставим Вам подробное коммерческое <br />
              предложение на разработку
            </p>
            <b-form @submit="submitForm">
              <b-form-group id="input-group-name" label-for="form_name">
                <p :class="[{ 'opacity-0': !form.name }, 'form__hint']">Имя</p>
                <b-form-input
                  id="form_name"
                  v-model="form.name"
                  type="text"
                  placeholder="Имя"
                  required
                />
              </b-form-group>
              <b-form-group id="input-group-phone" label-for="form_phone">
                <p :class="[{ 'opacity-0': !form.phone }, 'form__hint']">
                  Телефон
                </p>
                <b-form-input
                  id="form_phone"
                  v-model="form.phone"
                  type="text"
                  placeholder="Телефон"
                  required
                />
              </b-form-group>
              <b-form-group id="input-group-email" label-for="form_email">
                <p :class="[{ 'opacity-0': !form.email }, 'form__hint']">
                  Email
                </p>
                <b-form-input
                  id="form_email"
                  v-model="form.email"
                  type="email"
                  placeholder="Email"
                  required
                />
              </b-form-group>
              <b-form-group id="input-group-message" label-for="form_message">
                <p :class="[{ 'opacity-0': !form.message }, 'form__hint']">
                  Сообщение
                </p>
                <b-form-input
                  id="form_message"
                  v-model="form.message"
                  type="text"
                  placeholder="Сообщение"
                  required
                />
              </b-form-group>

              <button type="submit" class="btn button-text">Рассчитать</button>
              <p>
                <span class="opacity-50">Или напишите нам в месендежеры</span>
              </p>
              <div class="contacts__item social-media">
                <a href="https://t.me/dshipachev" class="benefit__icon">
                  <img src="@/assets/icons/telegram.svg" />
                </a>
                <a href="http://wa.me/79265222770" class="benefit__icon">
                  <img src="@/assets/icons/whatsapp.png" />
                </a>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>

    <b-alert
      :show="dismissCountDown"
      fade
      variant="warning"
      @dismiss-count-down="countDownChanged"
    >
      {{ alertText }}
    </b-alert>
  </div>
</template>

<script>
import NavigationComponent from "./components/Navigation.vue";
import CardComponent from "./components/Card.vue";
import BenefitComponent from "@/components/BenefitComponent";
import ProjectComponent from "@/components/ProjectComponent";
import axios from "axios";
import ArrowRight from "@/components/ArrowRight.vue";
import ArrowLeft from "@/components/ArrowLeft.vue";

export default {
  name: "App",
  components: {
    ProjectComponent,
    NavigationComponent,
    Card: CardComponent,
    BenefitComponent,
    ArrowLeft,
    ArrowRight,
  },
  data: function () {
    return {
      showModal: true,
      cardSettings: {
        centerMode: true,
        centerPadding: "16px",
        focusOnSelect: true,
        infinite: false,
        slidesToShow: 1,
        speed: 500,
      },
      cards: [
        {
          title: "10 лет+",
          description: "Опыта разработки мобильных приложений",
        },
        {
          title: "100+",
          description: "Завершенных проектов",
        },
        {
          title: "50+",
          description: "Специалистов в команде мобильной разработки ",
        },
      ],
      projects: [
        {
          title: "Premier",
          description:
            "Мобильное приложение, где можно смотреть сериалы и шоу от ТНТ, 2х2, ТВ-3 и остальных телеканалов Газпром-медиа. ",
          itemList: [
            "Дизайн интерфейса",
            "Мобильные приложения",
            "Приложения под ТВ",
          ],
          desktopImages: require("@/assets/projects/PremierWeb.png"),
          mobileImages: require("@/assets/projects/PremierMobile.png"),
        },
        {
          title: "Мобильное приложение Столото",
          description: "Гипермаркет лотерей для монополиста игрового бизнеса.",
          itemList: [
            "Сайт",
            "Личный кабинет",
            "Мобильные приложения",
            "Платежные интеграции",
            "SLA 24/7",
          ],
          desktopImages: require("@/assets/projects/StolotoWeb.png"),
          mobileImages: require("@/assets/projects/StolotoMobile.png"),
        },
        {
          title: "ТНТ-CLUB",
          description:
            "Мобильное приложение «главного развлекательного» c новостями звезд, программой лояльности и промо-акциями.",
          itemList: [
            "Программа лояльности",
            "Мобильные приложения",
            "CMS, CRM",
            "Игровые механики",
            "Лендинг",
          ],
          desktopImages: require("@/assets/projects/TNT-CLUBWeb.png"),
          mobileImages: require("@/assets/projects/TNT-CLUBMobile.png"),
        },
        {
          title: "СберЗвук Studio",
          description: "Платформа для талантов.",
          itemList: [
            "Программа лояльности для артистов",
            "Маркетплейс creative услуг",
            "Аналитический дашборд",
            "Рекламный кабинет артиста",
            "Медиаресурс",
          ],
          desktopImages: require("@/assets/projects/SberZvukWeb.png"),
          mobileImages: require("@/assets/projects/SberZvukMobile.png"),
        },
        {
          title: "SberBox",
          description:
            "Музыкальное мобильное приложение, позволяющее выводить музыку на любое устройство.",
          itemList: [
            "Мобильное приложение для управления музыкой",
            "Кроссплатформенное приложение для воспроизведения музыки на удаленном устройстве-сервере",
            "Управление контентом в CMS",
          ],
          desktopImages: require("@/assets/projects/SberBoxWeb.png"),
          mobileImages: require("@/assets/projects/SberBoxMobile.png"),
        },
        {
          title: "PulseTeam",
          description:
            "Корпоративное мобильное приложение, позволяющее собрать всех сотрудников компании в едином информационном пространстве.",
          itemList: [
            "Мобильное приложение iOS и Android",
            "Административная панель",
            "Дизайн, отражающий стиль компании",
          ],
          desktopImages: require("@/assets/projects/PulseTeamWeb.png"),
          mobileImages: require("@/assets/projects/PulseTeamMobile.png"),
        },
        {
          title: "GetTrasnsfer",
          description:
            "Приложение для международного сервиса бронирования трансферов, поездок и аренды автомобилей с водителем. Возможность планировать поездки заранее для частных лиц или целых компаний. ",
          itemList: [
            "Проектирование приложения",
            "Дизайн интерфейсов",
            "Мобильные приложения для iOS и Android.",
          ],
          desktopImages: require("@/assets/projects/GetTransferWeb.png"),
          mobileImages: require("@/assets/projects/GetTransferMobile.png"),
        },
      ],
      benefits: [
        {
          icon: require("@/assets/icons/Platforms.svg"),
          type: "svg",
          title: "Платформы",
          description: "Разработка под iOS и Android",
          classes: ["benefit", "right-bordered", "bottom-bordered"],
        },
        {
          icon: require("@/assets/icons/PaymentSystems.svg"),
          type: "svg",
          title: "Платежные системы",
          description: "Интеграция с платежными системами",
          classes: [
            "benefit",
            "right-bordered",
            "left-bordered",
            "bottom-bordered",
          ],
        },
        {
          icon: require("@/assets/icons/ProjectDevelopment.svg"),
          type: "svg",
          title: "Развитие проекта",
          description: "План по развитию проекта",
          classes: ["benefit", "left-bordered", "bottom-bordered"],
        },
        {
          icon: require("@/assets/icons/Integration.svg"),
          type: "svg",
          title: "Интеграция с текущей инфраструктурой",
          description: "ERP, CRM, 1C, базами данных, интернет-магазином и др",
          classes: ["benefit", "right-bordered", "top-bordered"],
        },
        {
          icon: require("@/assets/icons/Design.svg"),
          type: "svg",
          title: "Дизайн",
          description: "UI дизайн в современном тренде мобильной разработки",
          classes: [
            "benefit",
            "right-bordered",
            "left-bordered",
            "top-bordered",
          ],
        },
        {
          icon: require("@/assets/icons/RegistrationInStores.svg"),
          type: "svg",
          title: "Регистрация в сторах",
          description: "Помощь в регистрациях в AppStore и GooglePlay",
          classes: ["benefit", "left-bordered", "top-bordered"],
        },
      ],
      applicationTypes: [
        {
          title: "Маркетплейсы услуг и товаров",
          description:
            "Сервисы, позволяющие Заказчикам находить Исполнителей. Системы вида Яндекс Такси, Фриланс, YouDo, Profi.ru, InDriver",
        },
        {
          title: "Интернет-магазины, \nторговые площадки",
          description:
            "Возможна интеграция с существующим интернет-магазином, так и разработка с нуля",
        },
        {
          title: "Система управления \nобучением (LMS)",
          description:
            "Дистанционное обучение, тесты, доступ к медиа контенту и т.д.",
        },
        {
          title: "Контроль сотрудников",
          description:
            "Мобильная  CRM, адаптированная под задачи вашего бизнеса: постановка задач сотрудникам, отслеживание хода работ, фотоотчеты, трекинг по GSP  и т.д",
        },
        {
          title: "Мессенджеры и соц.сети",
          description:
            "Приложения для коммуникации внутри компании или сообществ (Вконтакте, Instagram, Telegram, WhatsApp, Viber и т.д.)",
        },
        {
          title: "Системы лояльности",
          description:
            "Приложения для клиентов вашего бизнеса: карты лояльности, накопления бонусов, QR / штрих коды, отслеживания покупок и т.д.",
        },
      ],
      resultsDesktop: [
        [
          {
            title: "Сбор информации и аналитика",
            number: "1",
          },
          {
            title: "Техническое задание",
            number: "2",
          },
          {
            title: "Прототип приложения",
            number: "3",
          },
          {
            title: "UX/UI дизайн интерфейса",
            number: "4",
          },
        ],
        [
          {
            title: "Разработка ПО",
            number: "5",
          },
          {
            title: "Тестирование продукта",
            number: "6",
          },
          {
            title: "Публикация в сторах",
            number: "7",
          },
          {
            title: "Спринтовая разработка. Развитие системы",
            number: "8",
          },
        ],
      ],
      resultsMobile: [
        [
          {
            title: "Сбор информации и аналитика",
            number: "1",
          },
          {
            title: "Техническое задание",
            number: "2",
          },
        ],
        [
          {
            title: "Прототип приложения",
            number: "3",
          },
          {
            title: "UX/UI дизайн интерфейса",
            number: "4",
          },
        ],
        [
          {
            title: "Разработка ПО",
            number: "5",
          },
          {
            title: "Тестирование продукта",
            number: "6",
          },
        ],
        [
          {
            title: "Публикация в сторах",
            number: "7",
          },
          {
            title: "Спринтовая разработка. Развитие системы",
            number: "8",
          },
        ],
      ],
      competencies: [
        "Оптимизация Highload",
        "Кастомные CRM-системы",
        "Программы лояльности",
        "Анализ BigData",
        "Видеостриминговые платформы",
        "Мобильные приложения и сайты",
      ],
      form: {
        name: "",
        phone: "",
        email: "",
        message: "",
      },
      dismissCountDown: 0,
      alertText: "",
      screenWidth: document.documentElement.clientWidth,
    };
  },
  computed: {
    isDesktop() {
      return this.screenWidth > 992;
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.screenWidth = document.documentElement.clientWidth;
    },
    prev(id) {
      this.$refs[id].prev();
    },
    next(id) {
      this.$refs[id].next();
    },
    hideCollapseMenu() {
      if (this.$refs.childComponent.visibleCollapseMenu) {
        this.$refs.childComponent.hideCollapseMenu();
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCaxiosountDown = dismissCountDown;
    },
    submitForm(event) {
      event.preventDefault();
      axios
        .post(`${process.env.VUE_APP_API_URL}/rest/send-form`, this.form, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          this.alertText =
            "Заявка успешно подана. Мы свяжемся с вами в ближайшее время!";
          this.dismissCountDown = 5;
          this.showModal = false;
          this.form = {
            name: "",
            phone: "",
            email: "",
            message: "",
          };
        })
        .catch((err) => {
          this.alertText = "Произошла ошибка. Попробуйте позднее.";
          this.dismissCountDown = 5;
          this.showModal = false;
          this.form = {
            name: "",
            phone: "",
            email: "",
            message: "",
          };
          throw err;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
