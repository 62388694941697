<template>
  <div class="projects__item">
    <div class="projects__item-info">
      <h3 class="projects__item-title">{{ title }}</h3>
      <p class="projects__item-description opacity-50">{{ description }}</p>
      <b-list-group flush>
        <b-list-group-item v-for="(item, idx) in itemList" :key="idx">
          <span class="results__dot"><b-icon-dot /></span>
          <p>{{ item }}</p>
        </b-list-group-item>
      </b-list-group>

      <button
        v-if="isDesktop"
        type="button"
        class="btn button-text"
        data-toggle="modal"
        data-target=".bd-example-modal-lg"
        @click="$emit('showModal')"
      >
        Заказать похожий проект
      </button>
    </div>
    <div class="projects__item-images">
      <img v-if="isDesktop" :src="desktopImages" style="width: 100%" />
      <img v-else :src="mobileImages" />
    </div>
    <div v-if="!isDesktop" class="circle"></div>
    <button
      v-if="!isDesktop"
      type="button"
      class="btn button-text"
      data-toggle="modal"
      data-target=".bd-example-modal-lg"
      @click="$emit('showModal')"
    >
      Заказать похожий проект
    </button>
  </div>
</template>

<script>
export default {
  name: "ProjectComponent",
  props: {
    title: String,
    description: String,
    itemList: Array,
    desktopImages: String,
    mobileImages: String,
  },
  data: function () {
    return {
      screenWidth: document.documentElement.clientWidth,
    };
  },
  computed: {
    isDesktop() {
      return this.screenWidth > 1200;
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.screenWidth = document.documentElement.clientWidth;
    },
  },
};
</script>

<style scoped lang="scss">
.projects {
  &__item {
    position: initial;
    height: 100%;
    border-radius: 30px;
    display: flex !important;
    background: #fbfbfb;
    padding: 70px;
    position: relative;
    overflow: hidden;
    &.simpleDesign {
      .projects__item-images {
        width: 60%;
        max-width: 750px;
        left: 50%;
        align-items: flex-start;
      }
    }
    .btn {
      z-index: 2;
    }
    &-images {
      height: inherit;
      position: absolute;
      z-index: 2;
      display: flex;
      left: 24%;
      align-items: center;
      width: 90%;
    }
    &-info {
      z-index: 3;
      width: 432px;
    }
    .button-text {
      margin: 60px 0 44px;
    }
    &-title {
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 24px;
    }
    &-description {
      margin-bottom: 20px;
    }
    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 140%;
    }
    .list-group {
      &-item {
        padding: 0;
        p {
          margin-bottom: 0;
        }
      }
    }
    .circle {
      z-index: 1;
      border-radius: 50%;
      width: 706px;
      height: 706px;
      right: -80px;
      bottom: -32%;
      position: absolute;
      background: #f3ff6c;
    }
  }
}
@media (max-width: 1410px) {
  .projects__item {
    padding: 60px 90px 0;
  }
}
@media (max-width: 1200px) {
  .projects__item {
    padding: 40px 90px 0;
    flex-direction: column;
    align-items: center;
    border-radius: 0;
    &-info {
      margin-left: 0;
      width: 100%;
      h3 {
        font-size: 20px;
      }
      p {
        font-size: 16px;
      }
    }
    &-images,
    &.simpleDesign .projects__item-images {
      width: 400px;
      margin: 40px auto 0;
      max-width: none;
      display: block;
      right: auto;
      left: auto;
      bottom: 115px;
      height: fit-content;
      img {
        width: 100%;
      }
    }
    &:nth-child(odd) {
      .projects__item-images {
        top: auto;
        right: auto;
      }
    }
    .button-text {
      margin: 32px 90px;
      width: calc(100% - 180px);
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0;
    }
    .circle {
      width: 133vw;
      height: 133vw;
      max-height: 500px;
      left: -16.5vw;
      bottom: -100px;
    }
  }
}
@media (max-width: 992px) {
  .projects__item {
    padding: 24px 20px 0;
    &-info {
      margin-left: 0px;
      width: 100%;
    }
    &-images {
      margin-bottom: 24px;
    }
    &-title {
      margin-bottom: 7px;
    }
    &-description {
      opacity: 0.5;
      margin-bottom: 24px;
    }
    .button-text {
      margin: 32px 16px;
      width: calc(100% - 32px);
      bottom: 0;
    }
    .list-group-item {
      margin-bottom: 24px;
    }
  }
}
@media (max-width: 768px) {
  .projects__item-images {
    width: 100%;
    height: fit-content;
    max-width: 480px;
    padding: 0 40px;
  }
}
</style>
