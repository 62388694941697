<template>
  <svg
    width="7"
    height="12"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.17043 6.4073C-0.0568099 6.18216 -0.0568099 5.81843 0.17043 5.5927L5.56358 0.244292C5.89162 -0.0814308 6.42535 -0.0814308 6.75397 0.244292C7.08201 0.570015 7.08201 1.09866 6.75397 1.42438L2.14042 6.00029L6.75397 10.575C7.08201 10.9013 7.08201 11.43 6.75397 11.7557C6.42535 12.0814 5.89162 12.0814 5.56358 11.7557L0.17043 6.4073Z"
      fill="black"
    />
  </svg>
</template>
<script>
export default {
  name: "ArrowLeft",
};
</script>
