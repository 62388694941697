<template>
  <svg
    width="7"
    height="12"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.82957 6.4073C7.05681 6.18216 7.05681 5.81843 6.82957 5.5927L1.43642 0.244292C1.10838
      -0.0814308 0.574654 -0.0814308 0.24603 0.244292C-0.0820103 0.570015 -0.0820103 1.09866 0.24603
      1.42438L4.85958 6.00029L0.24603 10.575C-0.0820103 10.9013 -0.0820103 11.43 0.24603 11.7557C0.574654
      12.0814 1.10838 12.0814 1.43642 11.7557L6.82957 6.4073Z"
      fill="black"
    />
  </svg>
</template>
<script>
export default {
  name: "ArrowRight",
};
</script>
