<template>
  <b-row :id="id">
    <b-col v-if="icon" class="benefit__icon-col">
      <div class="benefit__icon">
        <b-icon v-if="type === 'b-icon'" :icon="icon" />
        <img v-if="type === 'svg'" :src="icon" />
      </div>
    </b-col>
    <b-col class="benefit__info">
      <h3 class="benefit__title">{{ title }}</h3>
      <p class="benefit__description opacity-50">{{ description }}</p>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "BenefitComponent",
  props: {
    title: String,
    type: String,
    description: String,
    icon: String,
    id: String,
  },
  mounted() {
    const el = document.getElementById(this.$props.id);
    const title = el?.querySelectorAll(".benefit__title")[0];
    const desc = el?.querySelectorAll(".benefit__description")[0];
    if (desc) desc.innerHTML = this.description;
    if (title) title.innerHTML = this.title;
  },
};
</script>

<style lang="scss">
.benefit {
  display: flex;
  flex-direction: row;
  &__icon-col {
    width: 50px;
    height: 50px;
    flex: none;
    padding: 0;
  }
  &__icon {
    width: 100%;
    height: 100%;
    background: var(--accent-color);
    border-radius: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
    & > * {
      width: 20px;
      height: 20px;
    }
  }
  &__info {
    padding-left: 25px;
    flex-grow: 1;
  }
  &__title {
    margin-bottom: 12px;
  }
  .row {
    width: 100%;
    margin: 0;
  }
}

@media (max-width: 992px) {
  .benefit {
    margin-bottom: 20px;
    border: none !important;
    padding: 0 !important;
    &:not(:last-of-type) .benefit__info {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      padding-bottom: 20px;
    }
    &__icon {
      margin-bottom: 16px;
    }
  }
}
</style>
